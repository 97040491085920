import Gallery from './PictureGallery.svelte'

window.addEventListener('DOMContentLoaded', () => {
  const targets = document.querySelectorAll('[data-picture-gallery]')

  targets.forEach(target => {
    const props = JSON.parse(target.getAttribute('data-picture-gallery'))
    const targetGallery = new Gallery({
      target,
      props
    })

  })
})
