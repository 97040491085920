
const getQuery = index => {
  return `?p=${index + 1}`
}

const saveAndClearQuery = () => {
  window.localStorage.setItem('periodical-lastPage', getQueryPageIndex() - 1)

  const currentLocation = window.location.toString()

  window.history.replaceState(null, null, currentLocation.slice(0, currentLocation.indexOf('?')))
}

const onSlideChanged = ({ prev, current }) => {
  let currentLocation = window.location.toString()

  const queryIndex = currentLocation.indexOf('?')
  currentLocation = currentLocation.slice(0, queryIndex === -1 ? null : queryIndex)

  const currentQuery = getQuery(current.index)
  window.history.replaceState(null, null, currentLocation + currentQuery)
}

const registerForGallery = gallery => {
  gallery.on('slide_changed', onSlideChanged)
  gallery.on('close', saveAndClearQuery)
}

const getQueryPageIndex = () => {
  const search = window.location.search
  return parseInt(search.slice(search.indexOf('?p=') + 3)) - 1
}

const getSavedPageIndex = () => {
  return parseInt(window.localStorage.getItem('periodical-lastPage')) + 1
}

const conditionalOpen = gallery => {
  if (isNaN(getQueryPageIndex())) {
    return
  }

  gallery.openAt(getQueryPageIndex())
}

export default {
  registerForGallery,
  getQueryPageIndex,
  getSavedPageIndex,
  conditionalOpen
}
