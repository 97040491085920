import $ from 'jquery'

const open = (id) => {
  document.getElementById(id).classList.add('visible')
}

const close = (id) => {
  document.getElementById(id).classList.remove('visible')
}

$(() => {
  $('[data-modal-close]').on('click', evt => {
    close(evt.target.getAttribute('data-modal-close'))
  })
})

export default {
  open,
  close
}
