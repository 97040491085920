<script>
    import { fade } from 'svelte/transition';

    export let pictures = [];

    let state = {
        pictures: pictures.map(i => ({
            currentId:  0,
            loadingId: -1
        }))
    }

    function on_loaded(index, url, e) {
        state.pictures[index].currentId = state.pictures[index].loadingId;
        state.pictures[index].loadingId = -1;
    }

    function load_image(index, url) {
        let img = new Image();
        img.onload = (e) => on_loaded(index, url, e);
        img.src = url;
    }

    function navigate_previous(index) {
        if (state.pictures[index].loadingId != -1) return;

        if (state.pictures[index].currentId > 0) {
            state.pictures[index].loadingId = state.pictures[index].currentId - 1;
            load_image(index, pictures[index].images[state.pictures[index].loadingId].image_url);
        }
    }

    function navigate_next(index) {
        if (state.pictures[index].loadingId != -1) return;

        if (state.pictures[index].currentId + 1 < pictures[index].images.length) {
            state.pictures[index].loadingId = state.pictures[index].currentId + 1;
            load_image(index, pictures[index].images[state.pictures[index].loadingId].image_url);
        }
    }

</script>

{#each pictures as picture, index (picture.id)}
    <div  class="picture-entry d-flex align-center text-center flex-column mb-5">
        <div class="h5 mb-0 text-grey">
            {#if picture.author_url}
            <a href={picture.author_url}>
                {picture.author}
            </a>
            {:else}
            <div>
                {picture.author}
            </div>
            {/if}
        </div>
        <h3 class="h2 mt-1">{picture.title}</h3>

        <!-- @note use svg icon set with xlink -->

        {#if state.pictures[index].currentId > 0}
            <svg transition:fade class="img-nav" on:click={() => navigate_previous(index)} width="66" height="78" viewBox="0 0 66 78" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: scaleX(-1)">
                <path d="M66 39L-3.58893e-06 77.1051L-2.57673e-07 0.894879L66 39Z" fill="#333333"/>
            </svg>
        {/if}

        <div class="img-wrapper align-center">
            <!-- {#if state.pictures[index].loadingId != -1}
                <div transition:fade class="loading">Loading...</div>
            {/if} -->

            {#each pictures[index].images as image, i}
                {#if state.pictures[index].currentId == i}
                    <img transition:fade src="{image.image_url}" alt="...">
                {/if}
            {/each}

            {#if picture.images[state.pictures[index].currentId].title}
                <div transition:fade class="image-title mt-3">{picture.images[state.pictures[index].currentId].title}</div>
            {/if}
        </div>

        {#if state.pictures[index].currentId + 1 < pictures[index].images.length}
            <svg transition:fade class="img-nav right" on:click={() => navigate_next(index)} width="66" height="78" viewBox="0 0 66 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M66 39L-3.58893e-06 77.1051L-2.57673e-07 0.894879L66 39Z" fill="#333333"/>
            </svg>
        {/if}

    </div>
{/each}
