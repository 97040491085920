
// dependencies
import jQuery from 'jquery'
import GLightbox from 'glightbox'
import 'bootstrap'

// internal
import './styles/app.scss'
import './styles/backend.scss'

// components

import modal from './modal'
import periodical from './periodical'

import './components/pictureGallery'

window.jQuery = window.$ = jQuery
window.GLightbox = GLightbox

// exposed types
window.$.atd = window.$.atd || {}
window.$.atd.modal = modal
window.$.atd.periodical = periodical
